import React, { Component } from 'react';
import { ListItem } from '@material-ui/core';

class Contact extends Component {
    render() {
        if (this.props.data) {
            var name = this.props.data.name;
            var street = this.props.data.address.street;
            var city = this.props.data.address.city;
            var state = this.props.data.address.state;
            var zip = this.props.data.address.zip;
            var phone = this.props.data.phone;
            var email = this.props.data.email;
            var message = this.props.data.contactmessage;
        }

        return (
            <section id="contact">
                <div className="row section-head">
                    <div className="two columns header-col">
                        <h1>
                            <span>Get In Touch.</span>
                        </h1>
                    </div>

                    <div className="ten columns">
                        <p className="lead">{message}</p>
                        <ListItem>
                            <a href="mailto:bradley@genao.io" target="_top">
                                <strong>Click to Contact Me</strong> </a>
                        </ListItem>

                    </div>
                </div>

                <div className="row">
                    <div className="eight columns"></div>

                    <aside className="four columns footer-widgets">
                        <div className="widget widget_contact">
                            <h4>Address and Phone</h4>
                            <p className="address">
                                {name}
                                <br />
                                {city}, {state} 
                                <br />
                                <span>{phone}</span>
                            </p>
                        </div>

                        <div className="widget widget_tweets">
                            <h4 className="widget-title">
                                Latest Read Meduim articles
                            </h4>
                            <ul id="twitter">
                                <li>
                                    <span>
                                        Data Structures and Algorithms are at
                                        the base of almost every application we
                                        code, every project we create.
                                        Interviews for developers involve DSA as
                                        it helps test logic and problem-solving
                                        skills of the candidate. In recent
                                        years, there has been an enormous growth
                                        in the number of competitive programming
                                        websites and courses provided in DSA.
                                        <a href="/#">
                                            https://vritika.medium.com/roadmap-to-learning-data-structures-and-algorithms-dsa-8fbc375b5701
                                        </a>
                                    </span>
                                    <b>
                                        <a href="/#">2 Days Ago</a>
                                    </b>
                                </li>
                                <li>
                                    <span>
                                        These two groups have very diverse
                                        needs. However, people in both groups
                                        express some difficulty with solving
                                        Leetcode problems . In my experience,
                                        this comes from using
                                        Leetcode/HackerRank, etc in an
                                        inefficient way. They typically spend
                                        hour-1.5 hours on the problem. Then they
                                        give up and look at the solution. And
                                        they repeat this process. This is fine,
                                        but this is missing a crucial
                                        ingredient. Feedback. In the rest of
                                        this article, I will elaborate on what
                                        this ingredient is, why it’s important
                                        and how to integrate it. Using my
                                        experience helping people as my base.
                                        <a href="/#">
                                            https://medium.com/geekculture/the-4-step-method-my-students-use-to-maximize-leetcode-problems-and-ace-their-faang-interviews-2d5e0a6b1538
                                        </a>
                                    </span>
                                    <b>
                                        <a href="/#">3 Days Ago</a>
                                    </b>
                                </li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </section>
        );
    }
}

export default Contact;
